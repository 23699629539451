.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  
  .custom-card-header {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .custom-card-body {
    margin-top: 10px;
  }
  
  .custom-card-footer {
    margin-top: 20px;
    font-weight: bold;
  }
  
  .custom-card {
    width: 300px;
    height: 300px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  };
  