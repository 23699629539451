body {
  background-position: 0px;
  background-image: radial-gradient(circle 500px at top center, rgba(198, 255, 221, 0.2), rgba(251, 215, 134, 0.2), rgba(247, 121, 125, 0.1), rgba(0, 0, 0, 0));
  background-repeat: no-repeat;
  min-height: 100vh;
}

.main-view {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 75%;
  margin: auto;
}

kbd {
  background-color: #ccc !important;
  border-radius: 5px;
  padding: 5px;
  margin-left: 5px;
}

.App input { 
  margin-top: 20px;
}

.App button {
  margin-top: 20px;
}

.hero-text {
  font-size: 50px;
  font-weight: bold;
  text-align: center;
  margin-top: 50px;
}