.footer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 30px;
  } 
  
  .footer-text {
    display: flex;
    width: 80%;
  }
  
  .footer-links {
    display: flex;
    flex-direction: row;
    gap:20px;
  }
  
  .footer-links a {
    padding-right: 20px;
    border-right: 1px solid #ccc;
    text-decoration: none;
  }
  
  .footer-links a:first-child {
    margin-left: 20px;
  }
  
  .footer-links a:last-child {
    border-right: none;
  }
  
  .footer-social {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    width: 20%;
  }
  