.logo {
    display: flex;
    text-transform: uppercase;
}

.logo a {
    text-decoration: none;
    color: black;
}

.menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    width: -webkit-fill-available;
}

.menu a { 
    text-decoration: none;
}

.logo img {
    width: 80px;
    height: auto;
    margin-right: 10px;
}